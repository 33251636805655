import { create } from 'zustand';
import { clusterNamesAPI, clusterSizesAPI, getAllTShirtSizes } from 'apis';
import { convertAmountToFloat } from 'helpers';
import _ from 'lodash';
import { PROCUREMENT_TYPES } from 'types';

// Initialize Zustand store
const useMasterDataStore = create((set, get) => ({
    allClusterNames: [],
    allClusterSizes: [],
    allCulsterSizesForNew:[],
    allCulsterSizesForExpansion:[],

    getAllClusterNames: () => {
        const { allClusterNames } = get();
        return [...allClusterNames];
    },

    getAllClusterSizes: () => {
        const { allClusterSizes } = get();
        return [...allClusterSizes];
    },

    getAllCulsterSizesForNew: () => {
        const { allCulsterSizesForNew } = get();
        return [...allCulsterSizesForNew];
    },

    getAllCulsterSizesForExpansion: () => {
        const { allCulsterSizesForExpansion } = get();
        return [...allCulsterSizesForExpansion];
    },

    initializeMasterData: async () => {
        let _allClusterNames = await clusterNamesAPI();
        if (_allClusterNames.success) {
            set({ allClusterNames: _allClusterNames.data });
        } else {
            return false;
        }

        let _allClusterSizes = await clusterSizesAPI();
        if (_allClusterSizes.success) {
            if (_.isArray(_allClusterSizes.data)) {
                _allClusterSizes.data.forEach((item) => {
                    item['price'] = convertAmountToFloat(item?.['price']);
                });
            }
            set({
                allClusterSizes: _allClusterSizes.data.sort(),
            });
        } else {
            return false;
        }

        let _allCulsterSizesForNew = await getAllTShirtSizes(PROCUREMENT_TYPES.NEW);
        if (_allCulsterSizesForNew.success) {
            set({ allCulsterSizesForNew: _allCulsterSizesForNew.data });
        } else {
            return false;
        }

        let _allCulsterSizesForExpansion = await getAllTShirtSizes(PROCUREMENT_TYPES.EXPANSION);
        if (_allCulsterSizesForExpansion.success) {
            set({ allCulsterSizesForExpansion: _allCulsterSizesForExpansion.data });
        } else {
            return false;
        }

        return true;
    },
}));

export default useMasterDataStore;
