import { Col, Result, Row } from "antd"
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react"
import useMasterDataStore from "store/master_data_store"

const PostAuthenticationInit = ({ children }) => {

    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(null)
    const { initializeMasterData } = useMasterDataStore()
    useEffect(() => {
        initializeMasterData()
            .then(() => setLoading(false) || setSuccess(true))
            .catch((e) => {
                console.error(e)
                setSuccess(false)
            })
    }, [initializeMasterData])

    return success === true && !loading ? children : loading
        && success === null ? (<Row style={{
            minHeight: '100vh',
            background: "white",
        }} align={'middle'} justify={'center'}>
            <Col span={20}>
                <Result
                    icon={<LoadingOutlined />}
                    title="Initializing, please wait ..."
                />
            </Col>
        </Row>
    ) : (<Row style={{
        minHeight: '100vh',
        background: "white",
    }} align={'middle'} justify={'center'}>
        <Col span={20}>
            <Result
                status={'error'}
                title="Failed to initialize application !"
                subTitle="Something went wrong when retriving master data. Please contact the admins."
            />
        </Col>
    </Row>
    )
}

export default PostAuthenticationInit