import { create } from 'zustand';
import { notification } from 'antd';
import version_map from 'config/version_map';
import { getQueryParams } from 'helpers';
import { DatabaseManager } from 'helpers/db';
import _ from 'lodash';


let dbManager = new DatabaseManager()

const useActiveProcurementStore = create((set, get) => ({
    currentClusterRaw: null,
    getCurrentClusterSizingInfo: async () => {
        const { currentClusterRaw } = get();
        if (!currentClusterRaw) return null;
        return _.get(
            currentClusterRaw,
            version_map[get().getCurrentClusterVersion()]['SIZING_INFO_PATH'],
            null
        );
    },

    getCurrentClusterId: () => {
        const { currentClusterRaw } = get();
        return currentClusterRaw ? currentClusterRaw['PK'] : null;
    },

    getCurrentClusterName: () => {
        const { currentClusterRaw } = get();
        return currentClusterRaw ? currentClusterRaw['cluster_name'] : null;
    },

    getCurrentProcurementName: () => {
        const { currentClusterRaw } = get();
        return currentClusterRaw ? currentClusterRaw['procurement_name'] : null;
    },

    getCurrentProcurementType: () => {
        const { currentClusterRaw } = get();
        return currentClusterRaw ? currentClusterRaw['procurement_type'] : null;
    },

    getCurrentClusterVersion: () => {
        const { currentClusterRaw } = get();
        return currentClusterRaw ? currentClusterRaw['version_number'] : null;
    },

    getCurrentClusterActiveStage: (stage_number = 1) => {
        const { currentClusterRaw } = get();
        if (currentClusterRaw && currentClusterRaw[`active_stage_${stage_number}`]) {
            return currentClusterRaw[`active_stage_${stage_number}`];
        }
        return '';
    },

    getCurrentClusterRaw: async (force_fetch = false, PK = null) => {
        const { currentClusterRaw, getCurrentClusterId } = get();
        const params = getQueryParams();
        const id = PK ? PK : params?.['PK'];

        if (currentClusterRaw && getCurrentClusterId() === id && !force_fetch) {
            return currentClusterRaw;
        }

        if (id) {
            let key = {PK: id, SK: id}
            try {
                const obj = await dbManager.getObjectById(
                    process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
                    key
                );
                set({ currentClusterRaw: obj.data });
                return obj.data;
            } catch (error) {
                notification.error({
                    message: 'Cluster Id to look for not provided in query params or argument'
                });
                set({ currentClusterRaw: null });
                return null;
            }
        } else {
            notification.error({
                message: 'Cluster Id to look for not provided in query params or argument'
            });
            set({ currentClusterRaw: null });
            return null;
        }
    }
}));

export default useActiveProcurementStore;
