import { Col, Row, Spin } from "antd"; 
import { Routes, Route, useLocation } from "react-router-dom"; 
import { Suspense, lazy, useEffect, useState } from "react"; 

import SingleProcurementPage from "./SingleProcurementPage";
const ProcurementsListPage = lazy(() => import("./ProcurementsListPage")); 
const IntakeWizard = lazy(() => import("./IntakeWizard")); 
const SingleProcurementPageV1 = lazy(() => import("./SingleProcurementPageV1")); 
const SingleProcurementPageV2 = lazy(() => import("./SingleProcurementPageV2")); 
const PrismDataPage = lazy(() => import("./PrismDataPage")); 
const BOMDataPage = lazy(() => import("./BOMDataPage")); 
const Dashboard = lazy(()=>import("./Dashboard/Dashboard"))
const Sizing = lazy(()=>import("./Sizing"))

const AppRoutes = () => { 
  const location = useLocation(); 
  const [contentSpan, setContentSpan] = useState(21); 
  useEffect(() => { 
    if (location.pathname === '/bom/info') { 
      setContentSpan(23); 
    } else { 
      setContentSpan(21); 
    } 
  }, [location]); 
  return ( 
    <> 
      <Row align={"middle"} justify="center"> 
        <Col xs={contentSpan} sm={contentSpan} md={contentSpan} lg={contentSpan} xl={contentSpan}> 
          <Row 
            align="top" 
            style={{ 
              minHeight: '200vh', 
              background: "white", 
              maxHeight: '200vh', 
              overflowY: 'auto' 
            }} 
          > 
            <Col span={24}> 
              <Suspense fallback={<Spin spinning={true} fullscreen />}> 
                &nbsp; 
                <Routes> 
                  <Route path="/" element={<ProcurementsListPage />}></Route> 
                  <Route path="/v1/procurement" element={<SingleProcurementPageV1 />}></Route> 
                  <Route path="/v2/procurement" element={<SingleProcurementPageV2 />}></Route> 
                  <Route path="/cluster/initialize" element={<IntakeWizard />}></Route> 
                  <Route path="/cluster/info/prism" element={<PrismDataPage />}></Route> 
                  <Route path="/bom/info" element={<BOMDataPage />}></Route> 
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                 
                  <Route path="/singleProcurementPage" element={<SingleProcurementPage />}></Route>
                  <Route path="/sizing" element={<Sizing />}></Route>
                </Routes> 
              </Suspense> 
              &nbsp; 
            </Col> 
          </Row> 
        </Col> 
      </Row> 
    </> 
  ); 
}; 
export default AppRoutes; 
 
