import { Layout, ConfigProvider } from "antd";
import AppFooter from "./components/common/Footer";
import AppHeader from "./components/common/Header";
import AppRoutes from "./components/pages/Routes";
import "./App.css"
import { BrowserRouter } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { auth_config } from 'config/aws_exports';
import Authenticator from "components/Authenticator";
import { AuthenticatorProvider } from "@aws-amplify/ui-react-core";
import PostAuthenticationInit from "components/PostAuthenticationInit";

Amplify.configure(auth_config)


const App = () => {
  return (
    <AuthenticatorProvider>
      <Authenticator>
        <PostAuthenticationInit>
          <BrowserRouter>
            <ConfigProvider
              theme={{
                token: {
                  // Seed Token
                  colorPrimary: "#00857C",
                  borderRadius: 2,

                  // Alias Token
                  colorBgContainer: "white",
                },
                components: {
                  Menu: { itemBg: "white", colorText: "black" },
                  Steps: {
                    colorBgTextActive: "yellow",
                  },
                },
              }}
            >
              <Layout>
                <AppHeader />
                <AppRoutes />
                <AppFooter />

              </Layout>
            </ConfigProvider>
          </BrowserRouter>
        </PostAuthenticationInit>
      </Authenticator>
    </AuthenticatorProvider>
  );
};
export default App;
