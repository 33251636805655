const auth_config = {
  "aws_project_region": process.env.REACT_APP_AWS_DEFAULT_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  "aws_cognito_region": process.env.REACT_APP_AWS_DEFAULT_REGION,
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  "oauth": {
    "domain": process.env.REACT_APP_AWS_USER_POOL_DOMAIN,
    "scope": ["email", "openid", "profile"],
    "redirectSignIn": window.location.protocol + '//' + window.location.host,
    "redirectSignOut": window.location.protocol + '//' + window.location.host + '/signout',
    "responseType": "code"
  }
};


//Testp@55
export { auth_config }