export const PROCUREMENT_TYPES = {
  'NEW': 'NEW',
  'EXPANSION': 'EXPANSION',
  'REFRESH': 'REFRESH'
}
export const SK_SEPERATOR = "|"

export const SK_PROC_STAGE_PREFIX = "STAGE" + SK_SEPERATOR

export const PROCUREMENT_STATUS = {
  'Initial': 'Initial',
  'Submitted': 'Submitted',
  'Approved': 'Approved'
}
export const ENTITY_TYPES = {
  'Procurement': 'Procurement',
  'ProcurementStage': 'ProcurementStage'
}

export const PROCUREMENT_STAGES = {
  'Intake': 'Intake',
  'Sizing': 'Sizing',
  'BOM': 'BOM',
  'Quote': 'Quote'
}

export const PROCUREMENT_PARENT_STAGES = {
  'DEMAND_MANAGEMENT': 'DEMAND_MANAGEMENT',
  'STAGE 2': 'STAGE 2'
}
export const PROCUREMENT_STAGES_MAP = {
  'DEMAND_MANAGEMENT': {
    'DEMAND_INTAKE': 'DEMAND_INTAKE',
    'SIZING': 'SIZING',
    'BILL OF MATERIALS': 'BILL OF MATERIALS',
    'QUOTE': 'QUOTE'
  }
}