import { FC, useMemo, memo } from "react";
import { ViewBuilderProps } from "./types";
import _ from "lodash";
import MaterialReactTableView from "./MaterialReactTableView";

const getColumnsFromViewData: (view_data: ViewBuilderProps['view_data'], table_library: ViewBuilderProps['table_library'], enable_editing_for: ViewBuilderProps['enable_editing_for']) => any[] = (view_data = [], table_library = 'ReactMaterialTable', enable_editing_for = []) => {
    if (view_data?.length > 0) {
        let cols: any[] = []
        if (table_library === 'ReactMaterialTable') {
            cols = Object.keys(view_data[0]).map((col) => {
                return {
                    accessorKey: col,
                    header: _.upperCase(col),
                    enableEditing: enable_editing_for.includes(col)
                };
            })
        }
        return cols
    } else {
        return []
    }
}

const defaultProps = {
    enable_editing_for: [],
    view_data: [],
}

const ViewBuilder: FC<ViewBuilderProps> = memo(({ view_data = defaultProps.view_data, table_library, hide_view_details = true,
    enable_editing_for = defaultProps.enable_editing_for, ...library_specific_props }) => {
    const columns = useMemo(() => {
        return getColumnsFromViewData(view_data, table_library, enable_editing_for)
    }, [view_data, table_library, enable_editing_for])

    return table_library === 'ReactMaterialTable' ? <MaterialReactTableView {...library_specific_props} columns={columns} data={view_data} /> : <></>
})

export default ViewBuilder