import { Button, Col, Form, notification, Row, Select, Spin, Typography } from "antd";
import { getAllTShirtSizes, updateProcurementStageItem } from "apis";
import React, { useEffect, useState } from "react";
import useMasterDataStore from "store/master_data_store";
const { Text } = Typography;

function ProcurementSizingStage({
  procurementSizingData,
  procurementType,
  isClusterSizeDisabled,
}) {
  const [estimatedTshirtSize, setEstimatedTshirtSize] = useState("");
  const { allClusterNames, allClusterSizes } = useMasterDataStore();
  const [procrementSizingDatastate, setProcrementSizingDatastate] = useState(
    {}
  );
  const [clusterSizes, setClusterSizes] = useState([]);
  const [showSpin, setShowSpin] = useState(false);

  useEffect(() => {
    setEstimatedTshirtSize(procurementSizingData["estimated_tshirt_size"]);
    setProcrementSizingDatastate(procurementSizingData);
    console.log("procurementType", procurementType);
    getTshirtSizesData();
  }, [procurementSizingData]);

  const getTshirtSizesData = async () => {
    if (procurementType) {
      let result = await getAllTShirtSizes(procurementType);
      console.log("TeshirtSizes", result.data);
      if (result.success) {
        setClusterSizes(result.data);
      } else {
        console.log(result.error);
      }
    }
  };

  const handleOnchangeDropdown = (value) => {
    setEstimatedTshirtSize(value);
  };

  const handleUpdateOnclick = () => {
    let currentData = procrementSizingDatastate;
    currentData = {
      ...currentData,
      estimated_tshirt_size: estimatedTshirtSize,
    };
    setProcrementSizingDatastate(currentData);
    updateSizing(currentData);
  };

  const updateSizing = async (currentData) => {
    ///change this code
    // setShowSpin(true)
    let res = await updateProcurementStageItem(currentData);
    if (res.success) {
      notification.success({
        message: "Update successful!!",
      });
      // setShowSpin(false)
    } else {
      notification.error({
        message:
          "Failed to save sizing info. Please try again or contact admin.",
      });
      // setShowSpin(false)
    }
    //TODO: bondes:
    // 1. Call getTShirtSizingForNewCluster(grand_cpu, grand_ram, grand_storage)
    // 2. Capture Tshirt_size value and populate Estimated Cluster Size dropdown by calling getAllTShirtSizes(PROCUREMENT_TYPES.NEW)
    // 3. Prepare SizingStageItem object - update size & price
    // 4. Call updateProcurementStageItem.
  };

  return (
    <div
      id="initial-demand-section"
      style={{
        backgroundColor: "white",
        padding: "40px",
        border: "3px solid #f6f6f6",
        borderRadius: "10px",
      }}
    >
      <Row gutter={[20, 0]}>
        <Text>Estimated Cluster Size:</Text>
        <Col span={16}>
          <Select
            // disabled={isClusterSizeDisabled}
            virtual={false}
            options={clusterSizes.map((item) => {
              return {
                label: item["Tshirt_size"],
                value: item["Tshirt_size"],
              };
            })}
            style={{ width: "70%" }}
            // value={estimatedTshirtSize?.replace("Gen-11", "").trim()}
            value={estimatedTshirtSize}
            onChange={handleOnchangeDropdown}
          />
        </Col>
        <Col span={16} >
          <Button type="primary" onClick={handleUpdateOnclick}>
            Update
          </Button>
        </Col>
        {showSpin && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <Spin size="large"
                tip="updating data..." />
              </div>
            )}
      </Row>
    </div>
  );
}

export default ProcurementSizingStage;
