const map: {
    [key: number]: {
        SIZING_INFO_PATH: string,
        DETERMINED_SIZING_INFO_PATH: string,
        EXPAND_PATH_DEMAND_INTAKE_FORM: string[],
        PROCUREMENT_TYPE_PATH: string,
        TSHIRT_SIZE_PATH: string
    }
} = {
    3: {
        SIZING_INFO_PATH: 'DEMAND_MGMT_INTAKE.renderValue.Demand Intake Form.formData',
        DETERMINED_SIZING_INFO_PATH: 'DEMAND_MGMT_SIZING.renderValue.Info.formData',
        EXPAND_PATH_DEMAND_INTAKE_FORM: ['DEMAND_MGMT',
            'DEMAND_MGMT_INTAKE',
            'DEMAND_MGMT_INTAKE.renderValue.Demand Intake Form'],
        PROCUREMENT_TYPE_PATH: 'procurement_type',
        TSHIRT_SIZE_PATH: ''
    },
    4: {
        SIZING_INFO_PATH: 'initial_demand',
        DETERMINED_SIZING_INFO_PATH: '',
        EXPAND_PATH_DEMAND_INTAKE_FORM: [],
        PROCUREMENT_TYPE_PATH: 'procurement_type',
        TSHIRT_SIZE_PATH: ''
    }
}

export default map